import { Modal, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import "./InspectionWaitingModal.css";
import  CloseIcon from '@material-ui/icons/Close';

const InspectionWaitingModal = ({ text }) => {
  const [isOpen, setIsOpen] = useState(true);
  const forceQuit = () => {
    setIsOpen(false)
  }
  return (
    <>
      <Modal
        className="inspection-waiting-modal"
        open={isOpen}
      >
        <Paper className="inspection-waiting-modal-paper">
          <div className="inspection-waiting-modal-close" onClick={() => forceQuit()}><CloseIcon fontSize='large' /></div>
          <div><b>{text}</b></div>
          <div className='loader'></div>
        </Paper>
      </Modal>
    </>
  );
}
export default InspectionWaitingModal;