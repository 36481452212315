import { useState, useEffect } from "react";

const useSearchBar = (list, initKey) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [postCodeSearchResult, setPostCodeSearchResult] = useState([])
  const searchKey = initKey || "name";
  const handleSearchBarChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handlePostCodeSearchBarChange = (e) => {
    setSearchTerm(e.target.value);
    let lowerNumber;
    let higherNumber;
    if(e.target.value.includes("-")) {
      const newSearchTerm = e.target.value.split("-");
      if(Number(newSearchTerm[0]) <= Number(newSearchTerm[1])) {
        lowerNumber = Number(newSearchTerm[0]);
        higherNumber = Number(newSearchTerm[1]);
      }else {
        lowerNumber = Number(newSearchTerm[1]);
        higherNumber = Number(newSearchTerm[0]);
      }
      const numbersLength = higherNumber - lowerNumber + 1;
      if(numbersLength <= 1000 && list.length > 0) {
        let numbersSet = [];
        for(let i = 0; i < numbersLength; i++) {
          const numberToAdd = lowerNumber + i;
          numbersSet.push(numberToAdd.toString());
        }
        let result = [];
        list.forEach(listItem => {
          if(numbersSet.some(el => el === listItem[searchKey])) {
            result.push(listItem)        
          }
        }); 
        setPostCodeSearchResult(result)
      }
    } else {
      const result = list.filter((listItem) =>
        listItem[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPostCodeSearchResult(result)
    }
  }
  useEffect(() => {
    if(list.length > 0) {
    const result = list.filter((listItem) =>
      listItem[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResult(result);
    } else {
      setSearchResult(list)
    }
  }, [searchTerm, list, searchKey]);

  return { searchResult, handleSearchBarChange, searchTerm, handlePostCodeSearchBarChange, postCodeSearchResult };
};

export default useSearchBar;
